<template>
    <div class="TenantAccountUpdateInformationView ViewWrapper">
        <clv-head-meta :title="$t('Account')"></clv-head-meta>
        <view-header>
            <h1>{{ $t('Account') }}</h1>
        </view-header>
        <view-container>
            <div class="row mt-4">
                <div class="col-12 col-md-8">
                    <div class="gk-card">
                        <div class="p-3">

                            <!-- LOADER -->
                            <div class="ElementLoading-Placeholder" v-if="updating">
                                <element-loading :active="true" background-color="transparent"></element-loading>
                            </div>

                            <el-form :model="formFields"
                                     :rules="formFieldsRules"
                                     ref="newInstanceForm"
                                     :label-position="'top'"
                                     class="cltlr-form-label-style-1"
                                     @submit.native.prevent v-if="!updating">

                                <el-form-item :label="$t('Name')" prop="name">
                                    <el-input v-model="formFields.name"></el-input>
                                </el-form-item>

                                <el-form-item :label="i18nAddOnEnabled ? $t('Default Language') : $t('Language')" prop="type">
                                    <el-select v-model="formFields.defaultLanguageKey" class="w-100">
                                        <el-option :label="'English'" :value="'EN'">English</el-option>
                                        <el-option :label="'Greek (Ελληνικά)'" :value="'EL'">Greek (Ελληνικά)</el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item class="mt-2 mb-0">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <button type="button" class="btn btn-primary" @click="submitForm">{{ $t('common_action_save') }}</button>
                                            <!-- <button type="button" class="btn btn-secondary ml-2" @click="resetForm">{{ $t('common_action_cancel') }}</button> -->
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
import { TenantAccountService } from '@/common/services/api.service';
import ElValidationUtils from '@/common/utils/el-validation.utils';

/**
 * Tenant Account update info form view.
 *
 * @author Dimitris Gkoulis
 */
export default {
    name: 'TenantAccountUpdateInformationView',
    data () {
        return {
            i18nAddOnEnabled: false,
            // Persistence //////////
            updating: false,
            formFields: {
                tenantId: null,
                name: '',
                defaultLanguageKey: 'EN'
            },
            formFieldsRules: {
                // @help : keep the front-end and back-end validation the same [IMPORTANT]!!!!
                name: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(2, 100)
                ],
                defaultLanguageKey: [
                    ElValidationUtils.requiredValidator()
                ]
            }
        };
    },
    beforeMount () {
        // Get data from store and map them to formFields.
        const tenantAccount = this.$store.getters['application/tenantAccount'];
        this.formFields.tenantId = tenantAccount.tenantId;
        this.formFields.name = tenantAccount.name;
        this.formFields.defaultLanguageKey = tenantAccount.defaultLanguageKey;
        // noinspection JSUnresolvedVariable
        this.i18nAddOnEnabled = tenantAccount.details.addOns.i18nAddOn;
    },
    methods: {
        saveInstance () {
            // You should call 'submitForm' first. It validates the form.

            // Start loader.
            this.updating = true;

            const dto = {
                tenantId: this.formFields.tenantId,
                name: this.formFields.name,
                defaultLangKey: this.formFields.defaultLanguageKey
            };

            // Perform the save request.
            TenantAccountService.updateTenantAccountInformation(dto)
                .then(({ data }) => {
                    this.formFields.tenantId = data.tenantId;
                    this.formFields.name = data.name;
                    this.formFields.defaultLanguageKey = data.defaultLanguageKey;
                    this.toastDefault('Account updated');
                    // Reloading the page is necessary.
                    // By doing this, the store will be initialized with the new add-on.
                    // Also, some components (PExpl, Person, etc) will now use different components based on user choice.

                    // 2020-07-17 : Displays global loader while app reloading.
                    this.$store.commit('application/uiInitializingSet', true);
                    this.$store.dispatch('application/uiCheckAndSetReadiness');
                    setTimeout(function () {
                        location.reload();
                    }, 1000);
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                })
                .finally(() => {
                    // Stop loader.
                    this.updating = false;
                });
        },

        // Form Controls //////////
        submitForm () {
            // Start loader.
            this.updating = true;

            // Validate form.
            this.$refs['newInstanceForm'].validate((valid) => {
                if (valid) {
                    this.saveInstance();
                } else {
                    this.updating = false;
                    return false;
                }
            });
        },
        resetForm () {
            // @help : try-catch is necessary because form sometimes is hidden (due to 'not-found'). Normal behavior.
            try {
                this.$refs['newInstanceForm'].resetFields();
            } catch (ignoredError) {}
        }
    }
};
</script>
